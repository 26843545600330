import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../assets/images/padel_universe_logo.png";

export default function NavBar() {
  return (
    <Navbar expand="lg" className="navbar">
      <div className="container">
        <Navbar.Brand href="#home">
          <img src={logo} height="40" alt={"Padel Universe logo"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="mr-auto" />
          <a
            href="https://www.matchi.se/facilities/padeluniverse"
            rel="noreferrer"
            target="_blank"
            className="btn btn-lg book-button"
          >
            Book bane
          </a>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
